/* eslint-disable react/display-name */
import { AppStoreContext, StoreContextProvider } from 'contexts/index'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { AppTheme } from 'theme/index'
import { baseUploadsUrl, useTeaserSize } from 'utils/index'

import { Alert } from '../components'

import { renderAppIFrame } from './app_iframe'
import { renderModalIFrame } from './modal_iframe'
import { GlobalStyles } from './styles'
import Teaser from './teaser'

export interface TeaserProps {
  showZip: boolean
}

export const AppWithTeaser: React.FC<TeaserProps> = observer(({ showZip }) => {
  const store = useContext(AppStoreContext)

  const [isLoading, setIsLoading] = useState(true)
  const [appTitle, setAppTitle] = useState('Photovoltaik-Paket')
  const [validZips, setValidZips] = useState([] as string[])
  const [zip, setZip] = useState('')

  const teaserRef = useRef<HTMLDivElement>(null)
  const { teaserSize } = useTeaserSize(teaserRef)

  const teaserLBGImg = Boolean(store.ciSettings?.settings_sw_teaser_image_l)
    ? `${baseUploadsUrl}${store.ciSettings?.settings_sw_teaser_image_l}`
    : ''
  const teaserMBGImg = Boolean(store.ciSettings?.settings_sw_teaser_image_m)
    ? `${baseUploadsUrl}${store.ciSettings?.settings_sw_teaser_image_m}`
    : ''

  const customFontColor = store.ciSettings?.settings_sw_teaser_font_color

  const isGermanFormat = store.pvSettings?.form.plz_format === 'Deutschland' ?? false

  useEffect(() => {
    store.loadPVSettings().then((pvSettings: any) => {
      setAppTitle(pvSettings.form.heat_tool_name)
      setValidZips(pvSettings.form.plz.split(', '))
      setIsLoading(false)
    })
  }, [store])

  const validZip = () => {
    const regExp = isGermanFormat ? /^\d{5}$/gm : /^\d{4}$/gm
    const zipIsValid = regExp.test(zip.toString())

    if (showZip && !zipIsValid) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <Alert
              onClose={onClose}
              title='Ungültige PLZ'
              description='Bitte korrigieren Sie die Eingabe.'
            />
          )
        }
      })
    } else if (showZip && !validZips.includes(zip.toString(), 0)) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <Alert
              onClose={onClose}
              title='PLZ'
              description={
                Boolean(store.pvSettings.hint.hint__not_in_plz)
                  ? store.pvSettings.hint.hint__not_in_plz
                  : `In Ihrer Region bieten wir ${appTitle} derzeit nicht an.
            Trotzdem möchten wir Ihre Fragen beantworten. Dazu können Sie uns gerne
            kontaktieren: ${store.ciSettings?.settings_sw_text_sw_phone}`
              }
            />
          )
        }
      })
    } else {
      renderModalIFrame()
      renderAppIFrame()
    }
  }

  return (
    <ThemeProvider theme={AppTheme}>
      <StoreContextProvider>
        <div
          style={{ display: 'block', height: '100%', width: '100%', boxSizing: 'border-box' }}
          id='teaser__outer-container'
          ref={teaserRef}>
          {isLoading ? (
            <>Loading</>
          ) : (
            <Teaser
              showZip={showZip}
              zip={zip}
              setZip={setZip}
              bgImage={teaserSize === 'ls' ? teaserMBGImg : teaserLBGImg}
              customFontColor={customFontColor}
              size={teaserSize as 'll' | 'ls' | 'ml' | 'ms'}
              productName={appTitle}
              title={
                teaserSize === 'ls' || teaserSize === 'ms'
                  ? store.ciSettings?.settings_sw_teaser_title_s
                  : store.ciSettings?.settings_sw_teaser_title_l
              }
              subtitle={
                teaserSize === 'll'
                  ? store.ciSettings?.settings_sw_teaser_sub_title_l
                  : teaserSize === 'ls' && store.ciSettings?.settings_sw_teaser_sub_title_m
              }
              openApp={validZip}
            />
          )}
          <GlobalStyles />
        </div>
      </StoreContextProvider>
    </ThemeProvider>
  )
})

Teaser.displayName = 'Teaser'

export default AppWithTeaser

function renderTeaser(rootEl: HTMLElement, showZip: boolean) {
  ReactDOM.render(<AppWithTeaser showZip={showZip} />, rootEl)
}

const methodName = `render_teaser_m_pv`
;(window as any)[methodName] = renderTeaser
