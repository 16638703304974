import getGeneratedPageURL, {
  modalIFrameDocument,
  modalIFrameDocumentMobile
} from './utils/getGeneratedPageURL'

export function renderModalIFrame() {
  const iFrame: any = document.createElement('iframe')
  iFrame.id = 'modal_iframe'

  if (window.innerWidth < 968) {
    // Mobile
    iFrame.style.position = 'fixed'
    iFrame.style.right = '0px'
    iFrame.style.top = '0px'
    iFrame.style.width = '60px'
    iFrame.style.height = '60px'
    iFrame.style.border = 'none'
    iFrame.style.zIndex = '100000'
    iFrame.style.overflow = 'hidden'
  } else {
    // Laptop
    iFrame.style.position = 'fixed'
    iFrame.style.right = '0px'
    iFrame.style.top = '0px'
    iFrame.style.left = '0px'
    iFrame.style.bottom = '0px'
    iFrame.style.width = '100%'
    iFrame.style.height = '100%'
    iFrame.style.border = 'none'
    iFrame.style.zIndex = '99999'
    iFrame.style.overflow = 'hidden'
  }

  const url = getGeneratedPageURL(
    window.innerWidth < 968 ? modalIFrameDocumentMobile : modalIFrameDocument
  )

  const scrollY = window.parent.scrollY
  const body = window.parent.document.getElementsByTagName('body')[0]

  body.classList.add('freeze')
  body.style.top = `-${scrollY}px`

  const eventMethod = !!window.addEventListener ? 'addEventListener' : 'attachEvent'
  const eventer = window[`${eventMethod}`]
  const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'

  eventer(messageEvent, (e: any) => {
    if (e.data === 'close_app' || e.message === 'close_app') {
      body.removeChild(iFrame)
      body.style.top = ''
      body.classList.remove('freeze')
      window.parent.scrollTo(0, scrollY)
    }
  })

  iFrame.src = url
  body.appendChild(iFrame)
}

const methodNameForIFrame = `render_modal_iframe`
;(window as any)[methodNameForIFrame] = renderModalIFrame
