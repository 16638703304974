import {
  Button,
  Flex,
  Image,
  Space,
  SVG,
  SVGNames,
  TeaserTypography,
  TextField,
  Typography
} from 'components/index'
import React, { ChangeEvent } from 'react'
import { AppTheme } from 'theme/index'

import { BGContainer, TeaserContainer } from './styled-components'

interface TeaserProps {
  bgImage: string
  onOpenApp(): void
  onInputChange(event: ChangeEvent<HTMLInputElement>): void
  zip: string
  showZip: boolean
  productName?: string
  title?: string
  subtitle?: string
  fontColor?: string
}

export const TeaserLS: React.VFC<TeaserProps> = ({
  title,
  subtitle,
  bgImage,
  productName,
  showZip,
  zip,
  onOpenApp,
  onInputChange,
  fontColor
}) => {
  return (
    <BGContainer className='teaser_LS'>
      <TeaserContainer>
        <Space
          pt={`${AppTheme.spacingSize.mobile.M}px`}
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}
          height='100%'>
          <Space px={AppTheme.spacingSize.mobile.M}>
            <Typography type={TeaserTypography.PRODUCTNAME_M} color={fontColor}>
              {productName}
            </Typography>

            <Space
              mb={AppTheme.spacingSize.mobile.M}
              mt={`${AppTheme.spacingSize.mobile.M + AppTheme.spacingSize.mobile.XXS}px`}
              height='auto'>
              <Typography type={TeaserTypography.HEADLINE_M} color={fontColor}>
                {title === undefined ? '' : title.trim()}
              </Typography>
            </Space>

            {!!subtitle && (
              <Space mb={AppTheme.spacingSize.mobile.M}>
                <Typography type={TeaserTypography.SUBLINEA_M} color={fontColor}>
                  {subtitle}
                </Typography>
              </Space>
            )}
          </Space>

          {Boolean(bgImage) ? (
            <Image src={bgImage} display='block' width='100%' height='auto' />
          ) : (
            <SVG name={SVGNames.House} />
          )}

          <Space
            px={AppTheme.spacingSize.mobile.M}
            pt={AppTheme.spacingSize.mobile.M}
            height='auto'>
            <Flex flexDirection='row' justifyContent='center' alignItems='center' gap={'12px'}>
              {showZip && (
                <TextField
                  value={zip}
                  name='zip'
                  placeholder='Postleitzahl'
                  onInputChange={onInputChange}
                />
              )}
              <Button onClick={onOpenApp}>STARTEN</Button>
            </Flex>
          </Space>
        </Space>
      </TeaserContainer>
    </BGContainer>
  )
}
