import { Button, Flex, Space, TeaserTypography, TextField, Typography } from 'components/index'
import { rgba } from 'polished'
import React, { ChangeEvent } from 'react'
import { AppTheme } from 'theme/index'

import { BGContainer, TeaserContainer, TeaserTransparentAreaColor } from './styled-components'

interface TeaserProps {
  showZip: boolean
  productName?: string
  title?: string
  subtitle?: string
  bgImage: string
  onOpenApp(): void
  onInputChange(event: ChangeEvent<HTMLInputElement>): void
  zip: string
  fontColor?: string
}

export const TeaserML: React.VFC<TeaserProps> = ({
  title,
  subtitle,
  bgImage,
  productName,
  showZip,
  zip,
  onOpenApp,
  onInputChange,
  fontColor
}) => {
  const hasNoTextContent = !title && !subtitle
  return (
    <BGContainer className='teaser_LL'>
      <TeaserContainer bgImage={bgImage}>
        <Space
          height='100%'
          py={`${AppTheme.spacingSize.laptop.M + AppTheme.spacingSize.laptop.XXS}px`}
          px={AppTheme.spacingSize.laptop.L}>
          <Flex
            flexDirection='row'
            justifyContent='space-evenly'
            alignItems='center'
            className='teaser-flex-container'
            style={{
              height: '100%',
              borderRadius: '6px',
              gap: `${AppTheme.spacingSize.laptop.S}px`,
              background: !!bgImage
                ? hasNoTextContent
                  ? ''
                  : `${rgba(TeaserTransparentAreaColor, 0.5)}`
                : ''
            }}>
            <div
              style={{
                flexBasis: '55%',
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                justifyContent: hasNoTextContent ? 'flex-start' : 'space-evenly'
              }}>
              <Typography type={TeaserTypography.PRODUCTNAME} display='block' color={fontColor}>
                {productName}
              </Typography>
              <Typography color={fontColor} type={TeaserTypography.HEADLINE}>
                {title}
              </Typography>
            </div>

            <Flex
              style={{ height: '100%', flexBasis: '40%' }}
              gap='12px'
              flexDirection='row'
              justifyContent='flex-start'
              alignItems='center'>
              {showZip && (
                <TextField
                  value={zip}
                  name='zip'
                  placeholder='Postleitzahl'
                  onInputChange={onInputChange}
                />
              )}
              <Button onClick={onOpenApp}>STARTEN</Button>
            </Flex>
          </Flex>
        </Space>
      </TeaserContainer>
    </BGContainer>
  )
}
