import React, { ChangeEvent } from 'react'

import { TeaserFontColor, TeaserLL, TeaserLS, TeaserML, TeaserMS } from './components'

interface TeaserProps {
  showZip: boolean
  productName?: string
  title?: string
  subtitle?: string
  size: 'ml' | 'ms' | 'll' | 'ls'
  bgImage: string
  customFontColor?: string
  openApp?(): void
  zip: string
  setZip(value: string): void
}

export const Teaser: React.FC<TeaserProps> = ({
  showZip,
  size,
  productName,
  title,
  subtitle,
  bgImage,
  openApp,
  zip,
  setZip,
  customFontColor
}) => {
  const handleOpenApp = () => {
    if (openApp) {
      openApp()
    }
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setZip(event.currentTarget.value)
  }

  return (
    <>
      {size === 'll' && (
        <TeaserLL
          zip={zip}
          bgImage={bgImage}
          showZip={showZip}
          productName={productName}
          title={title}
          subtitle={subtitle}
          onOpenApp={handleOpenApp}
          onInputChange={handleInputChange}
          fontColor={customFontColor || TeaserFontColor}
        />
      )}

      {size === 'ml' && (
        <TeaserML
          zip={zip}
          bgImage={bgImage}
          showZip={showZip}
          productName={productName}
          title={title}
          subtitle={subtitle}
          onOpenApp={handleOpenApp}
          onInputChange={handleInputChange}
          fontColor={customFontColor || TeaserFontColor}
        />
      )}

      {size === 'ls' && (
        <TeaserLS
          zip={zip}
          bgImage={bgImage}
          showZip={showZip}
          productName={productName}
          title={title}
          subtitle={subtitle}
          onOpenApp={handleOpenApp}
          onInputChange={handleInputChange}
          fontColor={customFontColor || TeaserFontColor}
        />
      )}

      {size === 'ms' && (
        <TeaserMS
          zip={zip}
          showZip={showZip}
          productName={productName}
          title={title}
          onOpenApp={handleOpenApp}
          onInputChange={handleInputChange}
          fontColor={customFontColor || TeaserFontColor}
        />
      )}
    </>
  )
}

export default Teaser
