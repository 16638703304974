import {
  Button,
  Flex,
  Space,
  SVG,
  SVGNames,
  TeaserTypography,
  TextField,
  Typography
} from 'components/index'
import { rgba } from 'polished'
import React, { ChangeEvent } from 'react'
import { AppTheme } from 'theme/index'

import {
  AsideContainer,
  BGContainer,
  HorizonBar,
  SVGContainer,
  TeaserContainer,
  TeaserTransparentAreaColor
} from './styled-components'

interface TeaserProps {
  showZip: boolean
  productName?: string
  title?: string
  subtitle?: string
  bgImage: string
  onOpenApp(): void
  onInputChange(event: ChangeEvent<HTMLInputElement>): void
  zip: string
  fontColor?: string
}

export const TeaserLL: React.VFC<TeaserProps> = ({
  title,
  subtitle,
  bgImage,
  productName,
  showZip,
  zip,
  onOpenApp,
  onInputChange,
  fontColor
}) => {
  const hasNoTextContent = !title && !subtitle

  return (
    <BGContainer className='teaser_LL'>
      <TeaserContainer bgImage={bgImage} className='teaser_container'>
        <Space
          height='100%'
          py={`${AppTheme.spacingSize.laptop.L}px`}
          px={AppTheme.spacingSize.laptop.L}>
          <Flex
            style={{
              height: '100%',
              width: '50%',
              padding: '12px',
              borderRadius: '6px',
              background: !!bgImage
                ? hasNoTextContent
                  ? ''
                  : `${rgba(TeaserTransparentAreaColor, 0.5)}`
                : ''
            }}
            flexDirection='column'
            justifyContent='space-between'
            alignItems='flex-start'>
            <Typography type={TeaserTypography.PRODUCTNAME} display='block' color={fontColor}>
              {productName}
            </Typography>
            <Typography type={TeaserTypography.HEADLINE} color={fontColor}>
              {title}
            </Typography>
            {!!subtitle && (
              <Space mt={AppTheme.spacingSize.laptop.M}>
                <Typography type={TeaserTypography.SUBLINEA} color={fontColor}>
                  {subtitle}
                </Typography>
              </Space>
            )}
            <Space mt={AppTheme.spacingSize.laptop.M}>
              <Flex
                flexDirection='row'
                justifyContent='flex-start'
                alignItems='center'
                gap={'12px'}>
                {showZip && (
                  <TextField
                    value={zip}
                    name='zip'
                    placeholder='Postleitzahl'
                    onInputChange={onInputChange}
                  />
                )}
                <Button onClick={onOpenApp}>STARTEN</Button>
              </Flex>
            </Space>
          </Flex>
        </Space>
      </TeaserContainer>
      {!bgImage && (
        <AsideContainer>
          <HorizonBar />
          <SVGContainer>
            <SVG name={SVGNames.House} />
          </SVGContainer>
        </AsideContainer>
      )}
    </BGContainer>
  )
}
