import { lighten, readableColor } from 'polished'
import styled from 'styled-components'
import { AppTheme } from 'theme/index'

interface TeaserStylesProps {
  height?: number
  width?: number
  bgImage?: string
}

export const BGContainer = styled.div<TeaserStylesProps>`
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.primary};
  background-image: linear-gradient(
    to right,
    ${(props) => lighten(0.1, props.theme.colors.primary)},
    transparent
  );
`

export const TeaserContainer = styled.div<TeaserStylesProps>`
  height: 100%;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  background: ${(props) => `url(${props.bgImage})`};
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
  z-index: 1;
`

export const AsideContainer = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  z-index: 0;
`

export const HorizonBar = styled.div`
  width: 100%;
  height: 40px;
  background-color: ${(props) => props.theme.colors.secondary};
  display: block;
  bottom: 0px;
  position: absolute;
  z-index: 20;
`

export const SVGContainer = styled.div`
  position: absolute;
  right: 0px;
  bottom: 35px;
  width: 52%;
`

export const TeaserFontColor = readableColor(
  AppTheme.colors.primary,
  AppTheme.colors.black, // black
  AppTheme.colors.white, // white
  true
)

export const TeaserTransparentAreaColor =
  TeaserFontColor === AppTheme.colors.black ? AppTheme.colors.white : AppTheme.colors.black
