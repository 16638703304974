import { Button, Flex, Space, TeaserTypography, TextField, Typography } from 'components/index'
import React, { ChangeEvent } from 'react'
import { AppTheme } from 'theme/index'

import { BGContainer, TeaserContainer } from './styled-components'

interface TeaserProps {
  showZip: boolean
  productName?: string
  title?: string
  onOpenApp(): void
  onInputChange(event: ChangeEvent<HTMLInputElement>): void
  zip: string
  fontColor: string
}

export const TeaserMS: React.VFC<TeaserProps> = ({
  title,
  productName,
  showZip,
  zip,
  onOpenApp,
  onInputChange,
  fontColor
}) => {
  return (
    <BGContainer className='teaser_MS'>
      <TeaserContainer>
        <Space
          height='100%'
          px={AppTheme.spacingSize.mobile.M}
          pt={AppTheme.spacingSize.mobile.XL}
          pb={AppTheme.spacingSize.mobile.L}>
          <Flex flexDirection='column' alignItems='center' style={{ height: '100%' }}>
            <div
              style={{
                flexShrink: 6,
                height: '100%',
                whiteSpace: 'break-spaces'
              }}>
              <Space mb={`24px`} height='auto'>
                <Typography type={TeaserTypography.PRODUCTNAME_M} color={fontColor}>
                  {productName}
                </Typography>
              </Space>

              <Typography type={TeaserTypography.HEADLINE_M} color={fontColor}>
                {title === undefined ? '' : title.trim()}
              </Typography>
            </div>
            <div
              style={{
                height: '100%',
                flexShrink: 6,
                marginTop: '4rem'
              }}>
              <Flex
                gap={'12px'}
                style={{ height: '100%' }}
                flexDirection='row'
                justifyContent='center'
                alignItems='center'>
                {showZip && (
                  <TextField
                    value={zip}
                    name='zip'
                    placeholder='Postleitzahl'
                    onInputChange={onInputChange}
                  />
                )}
                <Button onClick={onOpenApp}>STARTEN</Button>
              </Flex>
            </div>
          </Flex>
        </Space>
      </TeaserContainer>
    </BGContainer>
  )
}
