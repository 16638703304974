import { useEffect, useState } from 'react'

type ObjectSize = { width: number; height: number }
type TeaserSize = 'll' | 'ml' | 'ls' | 'ms'

const checkTeaserSize = ({ width, height }: ObjectSize): TeaserSize => {
  if (width >= 750) {
    if (height >= 550) {
      return 'll'
    } else {
      return 'ml'
    }
  } else {
    if (height >= 458) {
      return 'ls'
    } else {
      return 'ms'
    }
  }
}

export function useTeaserSize(teaser: React.RefObject<HTMLDivElement>) {
  const [size, setSize] = useState<ObjectSize>({
    width: teaser?.current?.clientWidth || 0,
    height: teaser?.current?.clientHeight || 0
  })

  useEffect(() => {
    const observer = new ResizeObserver(([entry]) => {
      setSize({ width: entry.contentRect.width, height: entry.contentRect.height })
    })

    if (teaser.current) {
      observer.observe(teaser.current)
    }
    return () => {
      if (teaser.current) {
        observer.unobserve(teaser.current)
      }
      observer.disconnect()
    }
  }, [teaser])

  return { size, teaserSize: checkTeaserSize(size) }
}
