import styled, { createGlobalStyle, keyframes } from 'styled-components'

export const GlobalStyles = createGlobalStyle`

  div #teaserAutoPV {
    font-family: ${(props) => props.theme.fonts.family};
    *, *:before, *:after {
      box-sizing: border-box;
    }
  }

  .freeze {
    overflow: hidden;
  }

  .collapse-css-transition {
    transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .react-confirm-alert-overlay {
    z-index:10000!important;
  }

  .react-confirm-alert-blur {
    filter: url(#gaussian-blur);
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }
`

export const TeaserOuterContainer = styled.div`
  display: block;
  width: 100%;
  height: 100%;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
`

export const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const FadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
`

interface ModalStyleProps {
  top: string
  isOn: boolean
}
export const StyledModal = styled.div<ModalStyleProps>`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: ${(props) => props.top};
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  animation: ${(props) => (props.isOn ? FadeIn : FadeOut)} 0.5s ease;
  z-index: 99999;
  display: ${(props) => (props.isOn ? 'block' : 'none')};
`

export const QuitButton = styled.div`
  color: white;
  font-size: 2.8rem;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 0;
`
export const AppContainer = styled.div`
  height: calc(100% - 60px);
  width: calc(100% - 60px);
  position: fixed;
  left: 30px;
  top: 30px;
`
